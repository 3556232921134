<template>
  <div class="login_form" v-if="!isAutheticated">
    <div v-if="error" class="w-100 text-center alert alert-danger mb-3" role="alert">
      {{ error }}
    </div>
    <div class="input-group mb-1">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <svg class="bi" width="24px" height="24px">
            <use xlink:href="/assets/img/bootstrap-icons.svg#envelope-fill"></use>
          </svg>
        </div>
      </div>
      <input
        v-model="email"
        type="text"
        name="email"
        class="form-control"
        :placeholder="t('user.email')"
        :aria-label="t('user.email')"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <svg class="bi" width="24px" height="24px">
            <use xlink:href="/assets/img/bootstrap-icons.svg#lock-fill"></use>
          </svg>
        </div>
      </div>
      <input
        v-model="password"
        type="password"
        name="password"
        class="form-control"
        :placeholder="t('user.password')"
        :aria-label="t('user.password')"
      />
    </div>
    <button @click="login" class="btn btn-primary">{{ t("user.login") }}</button>
  </div>
  <div class="login_form min-vh-100 d-flex justify-content-center align-items-center" v-else>
    <div class="card text-center">
      <div class="card-body">
        <p class="card-text">
          {{ t("user.greetUser", { name: user.name }) }},<br />
          {{ t("user.alreadyLoggedin1") }}<br />
          {{ t("user.alreadyLoggedin2") }}
        </p>
        <button @click="logout" class="btn btn-primary btn-sm w-100">
          <svg class="bi" width="24" height="24" fill="currentColor">
            <use xlink:href="/assets/img/bootstrap-icons.svg#power"></use>
          </svg>
          {{ t("user.logout") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
export default {
  name: "Login",
  setup() {
    const email = ref(null);
    const password = ref(null);
    const error = ref(null);
    const store = useStore();

    const router = useRouter();
    const route = useRoute();

    const { t } = useI18n();

    async function login() {
      try {
        await store.dispatch("login", { email: email.value, password: password.value });
        if (route.redirectedFrom?.fullPath) {
          router.push(route.redirectedFrom.fullPath);
        } else {
          router.push("/");
        }
      } catch (err) {
        if (err?.status && (err.status === 401 || err.status === 422)) {
          error.value = t("error.wrongCredentials");
        } else {
          error.value = t("error.errorOccured");
        }
      }
    }

    async function logout() {
      await store.dispatch("logout");
      router.push("/login");
    }

    return {
      email,
      password,
      error,
      login,
      logout,
      user: computed(() => store.getters.user),
      // jwt: computed(() => (localStorage.getItem("jwt") ? true : false)),
      isAutheticated: computed(() => (store.getters.jwt ? true : false)),
      t,
    };
  },
};
</script>
